<template>
  <Fragment>
    <v-row no-gutters>
      <v-col cols="12" sm="12" md="12">
        <v-btn @click.stop="modal = true" class="primary" block>
          <v-icon color="white">mdi-account-arrow-down</v-icon> &nbsp;&nbsp;&nbsp; Auditoria del sistema 
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-dialog v-model="modal" persistent max-width="600px">
        <v-card>
          <v-card-title class="secondary white--text">
            Auditoria de usuario
          </v-card-title>
          <v-card-text class="pt-6">
            <ProgresiveLoadAutocompleteOld
              v-model="usuario"
              class="flex-grow-1 flex-shrink-1"
              style="min-width: 200px"
              label="Correo del usuario"
              placeholder="Ingrese un correo"
              itemText="email"
              itemValue="id"
              hide-details
              clearable
              :service="service"
              ref="progresive"
            />

            <template v-if="dataUsuario.length > 0">
              <div class="d-flex justify-center gap-3 flex-wrap align-center pt-8">
                <v-chip v-for="(dUsuario, index) in dataUsuario" :key="index">
                  {{ index + 1 }} - {{ dUsuario }}
                </v-chip>
              </div>
            </template>

            <template v-else-if="usuario">
              <div class="d-flex flex-column gap-1 mt-4" :style="`padding-bottom: ${menuFechaInicio || menuFechaFin ? '250px' : '0'}`">
                <p class="mb-0 mt-4"><strong>Seleccionar Rango de Fechas:</strong></p>

                <!-- Fecha de Inicio -->
                <v-menu v-model="menuFechaInicio" transition="scale-transition" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-text-field 
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="getFechaInicioParametrizable(fechaInicio)"
                        placeholder="Seleccionar fecha de inicio"
                        readonly
                        hide-details
                        outlined
                      />
                    </div>
                  </template>
                  <div @click.stop>
                      <vc-date-picker 
                        v-model="fechaInicio"
                        mode="dateTime" 
                        locale="es" 
                        :popover="config_calendar" 
                        :minute-increment="5"                  
                      />
                  </div>
                </v-menu>

                <!-- Fecha de Fin -->
                <v-menu v-model="menuFechaFin" transition="scale-transition" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-text-field 
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                        :value="getFechaInicioParametrizable(fechaFin)"
                        placeholder="Seleccionar fecha de fin"
                        readonly
                        hide-details
                        outlined
                      />
                    </div>
                  </template>
                  <div @click.stop>
                      <vc-date-picker 
                        v-model="fechaFin"
                        mode="dateTime" 
                        locale="es" 
                        :popover="config_calendar" 
                        :minute-increment="5"                  
                      />
                  </div>
                </v-menu>
              </div>
            </template>
          </v-card-text>

          <!-- Botones de acción -->
          <v-card-actions class="d-flex justify-center" style="position: sticky; bottom: 0;">
            <v-btn color="primary" :disabled="!fechaInicio || !fechaFin" @click="generarAhora">
              Generar Ahora
            </v-btn>
            <v-btn color="success" :disabled="!fechaInicio || !fechaFin" @click="programar">
              Programar
            </v-btn>
            <v-btn color="secondary" @click="cerrarModal">
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Modal para programar la Auditoria -->
    <ModalProgramarAuditoria/>
  </Fragment>
</template>

<script>
import { Fragment } from "vue-fragment";
import ProgresiveLoadAutocompleteOld from "@/components/utils/ProgresiveLoadAutocompleteOld.vue";
import UsuariosServicesV2 from "@/services/Usuarios.services.v2";
import ModalProgramarAuditoria from "../ProcesoCompraDoc/components/Etapas/hitoResultadosSubasta/ModalProgramarAuditoria.vue";
import { mapActions, mapMutations } from "vuex";

const servicio = UsuariosServicesV2.obtenerUsuarios;

export default {
  name: "gestionLimiteDescargas",
  components: {
    ProgresiveLoadAutocompleteOld,
    Fragment,
    ModalProgramarAuditoria,
  },
  data: () => ({
    modal: false,
    usuario: null,
    service: servicio,
    dataUsuario: [],
    fechaInicio: null,
    fechaFin: null,
    config_calendar: {
      visibility: "click",
      position: 'top',
    },
    // Menus
    menuFechaInicio: false,
    menuFechaFin: false,
  }),
  methods: {
    ...mapActions("auditoriaSubasta", [
      "generarInmediato",
    ]),

    ...mapMutations("auditoriaSubasta", [
      "setFechaInicio",
      "setFechaFin",
      "setModalProgramar",
      "setPorProveedor",
      "setBusqueda",
    ]),
    cerrarModal() {
      this.modal = false;
      this.dataUsuario = [];
      this.usuario = null;
      this.fechaInicio = null;
      this.fechaFin = null;
    },
    generarAhora() {
      console.log("Generando auditoría ahora con fechas:", this.fechaInicio, this.fechaFin);
      this.$store.commit("auditoriaSubasta/setPorProveedor", true);
      this.generarInmediato();
      this.cerrarModal();
    },
    programar() {
      console.log("Programando auditoría con fechas:", this.fechaInicio, this.fechaFin);
      this.$store.commit("auditoriaSubasta/setPorProveedor", true);
      this.setModalProgramar(true);
      this.cerrarModal();
    },
    getFechaInicioParametrizable(fecha) {
      return fecha ? new Date(fecha).toLocaleString("es-ES") : "";
    },
  },
  watch: {
    usuario(val) {
      if (val) {
        this.$store.commit("auditoriaSubasta/setBusqueda", val);
      }
    },
    fechaInicio(val) {
      if (val) {
        this.$store.commit("auditoriaSubasta/setFechaInicio", val);
      }
    },
    fechaFin(val) {
      if (val) {
        this.$store.commit("auditoriaSubasta/setFechaFin", val);
      }
    },
  },
};
</script>
