<template>
  <Fragment>
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <v-btn block class="primary" @click="confirmacion_modal = true">
          <v-icon class="mr-2" color="white">mdi-compass-rose</v-icon>
          Navegar a proceso de compra
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-dialog v-model="confirmacion_modal" persistent>
          <v-card>
            <v-card-title class="secondary white--text">
              Navegar a proceso de compra
            </v-card-title>
            <v-card-actions>
              <v-btn @click="confirmacion_modal = false">Cerrar</v-btn>
            </v-card-actions>
            <v-card-text class="pt-6">
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                    outlined
                    label="Nombre/Código del proceso"
                    v-model="form.busqueda"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <ProgresiveLoadAutocompleteOld
                    v-model="form.id_insitucion"
                    class="flex-grow-1 flex-shrink-1"
                    style="min-width: 200px"
                    label="Institución"
                    placeholder="Selecciones una institución"
                    itemText="nombre"
                    itemValue="id"
                    hide-details
                    clearable
                    :service="servicioInstituciones"
                    ref="progresive"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                    v-model="form.id_forma_contratacion"
                    :items="formasContratacion"
                    item-text="nombre"
                    item-value="id"
                    label="Forma de contratación"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                    v-model="form.id_anio_fiscal"
                    :items="aniosFiscales"
                    item-text="nombre"
                    item-value="id"
                    label="Año fiscal PAC"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                    v-model="form.id_seguimiento_proceso"
                    :items="seguimientoProceso"
                    item-text="nombre"
                    item-value="id"
                    label="Seguimiento de proceso"
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-btn
                    class="secondary mr-4 mt-2"
                    @click="buscarProcesoCompra"
                  >
                    <v-icon color="white"> mdi-magnify </v-icon>
                    Buscar
                  </v-btn>
                  <v-btn class="primary ml-4 mt-2" @click="limpiarFiltros">
                    <v-icon color="white"> mdi-delete </v-icon>
                    limpiar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-data-table
                    :headers="headers"
                    :items="procesos"
                    :items-per-page="10"
                    class="elevation-1"
                    :server-items-length="+totalItems"
                    :item-class="getRowClass"
                    @pagination="paginar"
                  >
                    <template v-slot:[`item.acciones`]="{ item }">
                      <v-icon @click="navegarProceso(item.id)">mdi-eye</v-icon>
                    </template>
                    <template v-slot:[`item.seguimiento`]="{ item }">
                      <v-chip
                        :color="item.SeguimientoProceso.color"
                        class="white--text"
                      >
                        {{ item.SeguimientoProceso.nombre }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </Fragment>
</template>

<script>
import ProgresiveLoadAutocompleteOld from "@/components/utils/ProgresiveLoadAutocompleteOld.vue";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import { Fragment } from "vue-fragment";
import bajaCuantiaServices from "@/services/BajaCuantia.services.js";

export default {
  name: "GenerarMigracionDisponibilidad",
  components: {
    ConfirmationDialog,
    Fragment,
    ProgresiveLoadAutocompleteOld,
  },
  data: () => ({
    confirmacion_modal: false,
    form: {
      busqueda: null,
      id_insitucion: null,
      id_anio_fiscal: null,
      id_forma_contratacion: null,
      id_seguimiento_proceso: null,
    },
    servicioInstituciones: bajaCuantiaServices.cargarInstitucionesOld,
    formasContratacion: [],
    aniosFiscales: [],
    seguimientoProceso: [
      {
        id: 1,
        nombre: "Configuración proceso",
      },
      {
        id: 11,
        nombre: "Recepción de solicitudes",
      },
      {
        id: 3,
        nombre: "En ejecución",
      },
      {
        id: 5,
        nombre: "Contratación",
      },
      {
        id: 44,
        nombre: "Desierto",
      },
      {
        id: 77,
        nombre: "Sin Efecto",
      },
      {
        id: 79,
        nombre: "Suspendido",
      },
      {
        id: 80,
        nombre: "Seguimiento contractual",
      },
      {
        id: 81,
        nombre: "Liquidado",
      },
      {
        id: 90,
        nombre: "Recurso de revisión",
      },
      {
        id: 91,
        nombre: "Recurso de apelación",
      },
    ],
    procesos: [],
    headers: [
      {
        text: "Nombre proceso",
        value: "nombre_proceso",
        align: "start",
        sortable: true,
      },
      {
        text: "Código proceso",
        value: "codigo_proceso",
        align: "start",
        sortable: true,
      },
      {
        text: "Código interno",
        value: "codigo_interno",
        align: "start",
        sortable: true,
      },
      {
        text: "Código PAC",
        value: "ProcesoCompraPac.correlativo",
        align: "start",
        sortable: true,
      },
      {
        text: "Institución",
        value: "Institucion.nombre",
        align: "start",
        sortable: true,
      },
      {
        text: "Forma de contratación",
        value: "FormaContratacion.nombre",
        align: "start",
        sortable: true,
      },
      {
        text: "Año fiscal",
        value: "ProcesoCompraPac.AnioFiscal.anio",
        align: "start",
        sortable: true,
      },
      {
        text: "Seguimiento del proceso",
        value: "seguimiento",
        align: "start",
        sortable: true,
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "start",
        sortable: true,
      },
    ],
    totalItems: 0,
    options: {
      page: 1,
      per_page: 10,
    },
  }),
  methods: {
    async obtenerModalidades() {
      const {
        data: { forma_contratacion },
      } = await this.services.Paac.getModalidades();
      this.formasContratacion = forma_contratacion;
    },
    async cargarAniosFiscales() {
      const { data } = await this.services.PacProcesos.cargarAniosFiscales();
      this.aniosFiscales = data;
    },
    async buscarProcesoCompra() {
      const {
        data,
        headers: { total_rows },
      } = await this.services.ProcesosCompraAdmin.listarProcesos(this.form);
      this.procesos = data;
      this.totalItems = total_rows;
    },
    async limpiarFiltros() {
      this.form = {
        busqueda: null,
        id_insitucion: null,
        id_anio_fiscal: null,
        id_forma_contratacion: null,
        id_seguimiento_proceso: null,
      };
      await this.buscarProcesoCompra();
    },
    navegarProceso(id) {
      const routeData = this.$router.resolve({
        name: "pac-procesos-etapas",
        params: { idProceso: id },
      });
      window.open(routeData.href, "_blank");
    },
    getRowClass(item) {
      if (item.deleted_at) {
        return "highlight-row";
      }
      return "";
    },
    async paginar({ page, itemsPerPage }) {
      this.options.page = page;
      this.options.per_page = itemsPerPage;
      await this.buscarProcesoCompra();
    },
    async mounted() {
      await this.obtenerModalidades();
      await this.cargarAniosFiscales();
    },
  },
};
</script>
<style>
.highlight-row {
  background-color: #ecb36d;
}
</style>
